import React, {useContext, useCallback, Dispatch, SetStateAction} from 'react';
import {useDropzone} from 'react-dropzone';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import classes from './file-input.module.css';

interface ImportedFile {
  fileName: string;
  fileContent:  string | ArrayBuffer | null;
}

interface FileInputProps {
  files: ImportedFile[];
  setFiles: Dispatch<SetStateAction<ImportedFile[]>>;
}

const FileInput: React.FC<FileInputProps> = ({files, setFiles}) => {
  const {theme} = useContext(GlobalStateContext);

  const onDrop = useCallback(acceptedFiles => {
    let filesInBase64 = acceptedFiles.map((file: File) => {
      const reader = new FileReader();
      reader.onload = e => {
        e.target && setFiles(prev => [...prev, {fileName: file.name, fileContent: reader.result}]);
      };
      reader.readAsDataURL(file);
      reader.onerror = error => console.log(error);
    });
  }, []);

  const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
    onDrop,
    accept: '.jpg, .png, .zip, .pdf, .doc, .docx, .xls, .xlsx',
  });

  return (
    <div className={clsx(classes.container, theme !== 'regular' && classes.black_and_white)}>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <button className={classes.btn} type="button">
          Вибрати
        </button>
        <p className={classes.extensions}>jpg, png, zip, pdf, doc, docx, xls, xlsx</p>
        <ul className={classes.file_list}>
          {files.map((file: ImportedFile) => (
            <li key={file.fileName}>{file.fileName}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FileInput;
