import React, {useContext} from 'react';
import {FormControlLabel, Checkbox, makeStyles} from '@material-ui/core';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';

interface CheckboxInputProps {
  value: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({value, handleChange}) => {
  const {theme} = useContext(GlobalStateContext);

  const useStyles = makeStyles(MUITheme => ({
    checkboxContainer: {
      display: 'flex',
      margin: '0 0 30px -5px',
      [MUITheme.breakpoints.down('xs')]: {
        margin: '0 0 30px -5px',
      },
    },
    checkbox: {
      color: theme == 'regular' ? '#fdc30a' : theme == 'blackOnWhite' ? '#000' : '#fff',
      transform: 'scale(2)',
      marginRight: '15px',
      '& svg': {
        fill: theme == 'whiteOnBlack' && '#fff',
      },
    },
  }));

  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.checkboxContainer}
      control={
        <Checkbox name="consent" checked={value} onChange={handleChange} required={true} className={classes.checkbox} />
      }
      label={`Дати свою згоду ТОВ "Тернопільелектропостач" на отримання, збереження та обробку внесених Вами персональних даних.`}
    />
  );
};

export default CheckboxInput;
