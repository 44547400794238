import React, {useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {navigate} from 'gatsby';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import ReCaptchaV2 from 'react-google-recaptcha';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import TextInput from '../components/text-input';
import FileInput from '../components/file-input';
import CheckboxInput from '../components/checkbox-input';
import SubmitButton from '../components/submit-button';
import classes from './contacts.module.css';

interface ImportedFile {
  fileName: string;
  fileContent: string | ArrayBuffer | null;
}

const OnlineTreament: React.FC = () => {
  const {theme} = useContext(GlobalStateContext);
  const [files, setFiles] = useState<ImportedFile[]>([]);

  const MuiTheme = createMuiTheme({
    palette: {
      primary: {
        main: theme == 'regular' ? '#fdc30a' : theme == 'blackOnWhite' ? '#000' : '#fff',
      },
      secondary: {
        main: theme == 'regular' ? '#fdc30a' : theme == 'blackOnWhite' ? '#000' : '#fff',
      },
    },
  });

  const {handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
    initialValues: {
      fullName: '',
      personalAccount: '',
      address: '',
      phone: '',
      email: '',
      appeal: '',
      consent: false,
      recaptcha: false,
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .matches(/^([^0-9]*)$/, "Ім'я не може містити цифр")
        .required("Обов'язкове поле"),
      personalAccount: Yup.string().required("Обов'язкове поле"),
      address: Yup.string().required("Обов'язкове поле"),
      phone: Yup.string().required("Обов'язкове поле"),
      email: Yup.string().email('Невірна адреса електронної пошти').required("Обов'язкове поле"),
      appeal: Yup.string().required("Обов'язкове поле"),
      consent: Yup.boolean(),
      recaptcha: Yup.boolean().oneOf([true]),
    }),
    onSubmit: values => {
      const formData = new FormData();
      formData.append('fullName', values.fullName);
      formData.append('personalAccount', values.personalAccount);
      formData.append('address', values.address);
      formData.append('phone', values.phone);
      formData.append('email', values.email);
      formData.append('appeal', values.appeal);
      if(files.length === 1) {
        files.map((file, index) => {
            const fileContent = JSON.stringify(file.fileContent)
            formData.append('fileContent', JSON.parse(fileContent))
            formData.append('fileName', file.fileName)
            return
        })
      } else if (files.length > 1) {
        const fileContent = JSON.stringify(files)
        formData.append('files', fileContent)
      } else {
        formData.append('fileContent', 'false')
      }


      fetch(process.env.APPS_SCRIPT_URL_FOR_CONTACT_FORM!, {
        method: 'POST',
        body: formData,
      })
        .then(res =>
          navigate('/submit-info/', {
            state: {title: res.ok === true ? 'Запит відправлено успішно' : 'Не вдалося відправити запит'},
          }),
        )
        .catch(err => navigate('/submit-info/', {state: {title: 'Виникла помилка, спробуйте пізніше'}}));
    },
  });

  return (
    <Layout>
      <Container>
        <PageTitle title="Онлайн звернення" />

        <form className={classes.form} onSubmit={handleSubmit}>
          <ThemeProvider theme={MuiTheme}>
            <TextInput
              name="fullName"
              type="text"
              label="Прізвище, ім’я, по батькові"
              value={values.fullName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.fullName}
              error={errors.fullName}
            />
            <TextInput
              name="personalAccount"
              type="text"
              label="Особовий рахунок"
              value={values.personalAccount}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.personalAccount}
              error={errors.personalAccount}
            />
            <TextInput
              name="address"
              type="text"
              label="Адреса проживання"
              value={values.address}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.address}
              error={errors.address}
            />
            <TextInput
              name="phone"
              type="tel"
              label="Контактний телефон"
              value={values.phone}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.phone}
              error={errors.phone}
            />
            <TextInput
              name="email"
              type="email"
              label="Електронна пошта"
              value={values.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.email}
              error={errors.email}
            />
            <TextInput
              name="appeal"
              type="text"
              label="Текст звернення"
              value={values.appeal}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.appeal}
              error={errors.appeal}
              multiline={true}
            />
            <FileInput files={files} setFiles={setFiles} />
            <CheckboxInput value={values.consent} handleChange={handleChange} />
            <ReCaptchaV2
              sitekey={process.env.RECAPTCHA_SITE_KEY}
              onChange={() => setFieldValue('recaptcha', true)}
              onExpired={() => setFieldValue('recaptcha', false)}
            />
            <SubmitButton />
          </ThemeProvider>
        </form>
      </Container>
    </Layout>
  );
};

export default OnlineTreament;
